import Button from 'react-bootstrap/Button';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
 <>
 <div className='container'>
 <Button target="_blank" href="https://nuez.tech" rel="noopener noreferrer">nuez.tech</Button>
 <Button target="_blank" href="https://smart.nuez.tech" rel="noopener noreferrer">smart.nuez.tech</Button>
 <Button target="_blank" href="https://dinabandhusahoo.in " rel="noopener noreferrer">dinabandhusahoo.in </Button>
 <Button target="_blank" href="https://shreeganeshpuri.in" rel="noopener noreferrer">shreeganeshpuri.in</Button>
 </div>
 </>
  );
}

export default App;
